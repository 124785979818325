import React from "react"

const NotFoundPage = () => (
  <div className="error-page">
    <div className="error-page_inner">
      <h1>Page not found</h1>
      <p>Looks like all the birds have flown away.</p>
    </div>
  </div>
)

export default NotFoundPage
